<template>
  <div class="user-about ly-wrap">
    <div class="currency-title"><span class="back" @click="goBack"></span>关于我们</div>
    <div class="about-box">
      <div class="about-info">
        <div class="it-box">
          <div class="i-item"><span class="a-user"></span>重庆云昇新控智能科技有限公司</div>
          <div class="i-item"><span class="a-phone"></span><a href="tel: 023-6870 9905">(023) 6780 9905</a></div>
          <div class="i-item"><span class="a-address"></span><a target="_blank"
                                                                href="http://api.map.baidu.com/marker?location=29.6473,106.555455&title=我的位置&content=重庆云昇新控智能科技有限公司&output=html&src=webapp.baidu.openAPIdemo">
            重庆市渝北区金开大道1003号C11-22
          </a></div>
        </div>
        <div class="dn-box">
          <div class="lf">
            <p>更多信息</p>
            <p>请关注公众号</p>
            <p>扫码</p>
            <p>或搜索</p>
            <a>云昇新控</a>
            <div class="point"></div>
          </div>
          <div class="rt">
            <div class="erw-img"></div>
          </div>
        </div>
      </div>
      <div class="app-info">
        <div class="app-box">
          <div class="lf">
            <div class="app-img"></div>
            <p style="margin-left: 0.42rem">扫码下载</p>
          </div>
          <div class="lf">
            <p>软件版本：V1.2</p>
            <div class="btn" @click="goWay('/user/agreement')">用户协议</div>
            <div class="btn" @click="goWay('/user/privacy')">隐私政策</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'UserSet',
  components: {},
  computed: {},
  data() {
    return {
      isGet: false,
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">

.user-about {
  width: 100%;
  height: auto;
  background-color: #EBF4FD;
  min-height: 100%;
  position: relative;

  .about-box {
    width: 100%;
    height: 13.81rem;
    background: url("../../assets/img/user/about-bg.png") no-repeat center;
    background-size: 7.5rem;

    .about-info {
      width: 6.71rem;
      height: 8.71rem;
      margin-left: 0.8rem;
      background: url("../../assets/img/user/ab-info-bg.png") no-repeat center;
      background-size: 6.71rem;

      .it-box {
        width: 3.8rem;
        height: 1.6rem;
        position: relative;
        top: 4rem;
        left: 1.08rem;

        .i-item {
          font-size: 0.24rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.4rem;

          a {
            color: #333333;
            text-decoration: none;
          }

          span {
            width: 0.24rem;
            height: 0.24rem;
            display: inline-block;
            position: relative;
            top: 0.02rem;
            background-size: 0.24rem;
            background-position: center;
            background-repeat: no-repeat;
            padding-right: 0.2rem;
          }

          .a-user {
            background-image: url("../../assets/img/user/a-user.png");
          }

          .a-phone {
            background-image: url("../../assets/img/user/a-phone.png");
          }

          .a-address {
            background-image: url("../../assets/img/user/a-address.png");
          }
        }
      }

      .dn-box {
        padding-top: 0.2rem;
        width: 3.8rem;
        height: 1.6rem;
        position: relative;
        top: 4rem;
        left: 1.08rem;
        line-height: 0.4rem;

        p {
          font-size: 0.22rem;
          font-weight: 400;
          color: #333333;
        }

        a {
          color: #0088FF;
        }

        .point {
          width: 0.61rem;
          height: 0.46rem;
          background: url("../../assets/img/user/point.png") no-repeat center;
          background-size: 0.61rem;
          position: absolute;
          left: 1rem;
          top: 1.1rem;
          animation: finger infinite 2s
        }

        .erw-img {
          width: 1.88rem;
          height: 1.88rem;
          background: url("../../assets/img/user/gzh.png") no-repeat center;
          background-size: 1.88rem;
        }
      }
    }

    .app-info {
      width: 7.45rem;
      height: 4.83rem;
      background: url("../../assets/img/user/ab-app-bg.png") no-repeat center;
      background-size: 7.45rem;
      position: relative;
      top: 1rem;

      .app-box {
        width: 5.3rem;
        height: 2.2rem;
        position: relative;
        left: 1.1rem;
        top: 1.95rem;
        line-height: 0.4rem;
        font-size: 0.22rem;
        font-weight: 400;
        color: #333333;

        .app-img {
          width: 1.89rem;
          height: 1.89rem;
          background: url("../../assets/img/user/app.png") no-repeat center;
          background-size: 1.89rem;
          margin-right: 0.5rem;
        }

        .btn {
          width: 1.28rem;
          height: 0.44rem;
          background: #FFFFFF;
          border: 0.03rem solid #0088FF;
          box-shadow: 0 0.04rem 0.04rem 0 rgba(0, 136, 255, 0.28);
          border-radius: 0.1rem;
          font-size: 0.2rem;
          line-height: 0.44rem;
          text-align: center;
          font-weight: 400;
          color: #0088FF;
          margin-top: 0.2rem;
        }
      }

    }

  }
}


@keyframes finger {

  0% {

    transform: translate(-5px)
  }


  25% {
    transform: translate(5px)
  }


  50% {
    transform: translate(-5px)
  }


  75% {
    transform: translate(5px)
  }

  100% {
    transform: translate(-5px)
  }

}
</style>
